import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
import {type DisparadorContentInterface} from './disparadorContentInterface';

export type VideoData = {
	url: string;
	imageUrl: string;
	title: string;
	order: number;
	id: string;
	duration?: string;
};

export default class DisparadorVideoContent implements DisparadorContentInterface {
	public static converter: FirestoreDataConverter<DisparadorVideoContent> = {
		toFirestore(object: DisparadorVideoContent): DocumentData {
			const filteredData = Object.fromEntries(Object.entries(object).map(([propertyName, value]) => {
				if (value !== undefined) {
					return [propertyName, value];
				}

				return [propertyName, null];
			}));
			return {...filteredData};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): DisparadorVideoContent {
			const data = snapshot.data() as DisparadorVideoContent;
			data.videos.sort((a, b) => a.order - b.order);
			return new DisparadorVideoContent(data.id, data.disparadorId, data.title, data.hashtag, data.summary, data.mainImageUrl, data.bodyText, data.author, data.videos, data.order);
		},
	};

	constructor(public id: string, public disparadorId: string, public title: string, public hashtag: string, public summary: string, public mainImageUrl: string, public bodyText: string, public author: string, public videos: VideoData[], public order: number) {
	}
}
