import React from 'react';
import theme from './singleSeason.theme.module.scss';
import type Disparador from '../../models/season';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import Image from '../image/image';
import Logo from '../logo/logo';
import {processText, replaceSpaces} from '../../consts';

type SingleSeasonProps = {
	season?: Disparador;
};

function SingleSeason({season}: SingleSeasonProps) {
	function proccessTextList(text: string): JSX.Element[] {
		return text
			.split('*')
			.filter(item => item.trim().length > 0)
			.map((item, index) => <li key={index}>{item.trim()}</li>);
	}

	return (
		<div id='temporada' className={theme.mainContainer}>
			<Logo size={320} classChildName={theme.giganticLogo} />

			<div className='container'>
				<AnimationOnScroll
					animateOnce={true}
					animateIn='animate__fadeIn'
					duration={1}
					className={theme.titleRow}
				>
					<h2>{replaceSpaces(season?.title)}</h2>
				</AnimationOnScroll>

				<AnimationOnScroll
					animateOnce={true}
					animateIn='animate__fadeIn'
					duration={1}
					className={theme.justifyLeft}
				>
					{processText(season?.description)}
				</AnimationOnScroll>

				<div className={theme.mainRow}>
					<Image
						gotToDisparadores
						title={season?.title}
						subTitle={season?.subTitle}
						smallImage={season?.coverImage}
					/>

					<AnimationOnScroll
						animateOnce={true}
						animateIn='animate__slideInRight'
						duration={1}
						className={theme.expanded}
					>
						<p>
							{season?.contentSummary
								? proccessTextList(season.contentSummary)
								: 'loading...'}
						</p>
						<a
							href='/disparadores'
							className='btn btn-custom btn-lg page-scroll'
						>
              QUIERO SABER MÁS
						</a>
					</AnimationOnScroll>
				</div>
			</div>
		</div>
	);
}

export default SingleSeason;
