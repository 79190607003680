import React, {useState} from 'react';
import theme from './uploadSongForm.theme.module.scss';
import Logo from '../logo/logo';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {mobileBreakpoint} from '../../consts';
import arrow from './arrow.svg';
import {uploadArtistSong} from '../../repositories/landingRepository';

type UploadSongFormProps = {
	disparadorName: string;
};

function UploadSongForm({disparadorName}: UploadSongFormProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	const [songFile, setSongFile] = useState<File | undefined>(undefined);
	const [lyricsFile, setLyricsFile] = useState<File | undefined>(undefined);
	const [email, setEmail] = useState<string>('');
	const [invalidMail, setInvalidMail] = useState<boolean>(false);
	const [noLyrics, setNoLyrics] = useState<boolean>(false);
	const [noSong, setNoSong] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [generalError, setGeneralError] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const handleSubmit = async () => {
		const form = document.getElementById(
			'artist-submit-form',
		) as HTMLFormElement;
		setInvalidMail(false);
		setNoLyrics(false);
		setNoSong(false);
		setIsLoading(true);
		setGeneralError(false);
		if (form.checkValidity()) {
			try {
				await uploadArtistSong(email, lyricsFile!, songFile!, disparadorName);
				setIsSuccess(true);
				setTimeout(() => {
					setIsSuccess(false);
				}, 10000);
				setEmail('');
				setLyricsFile(undefined);
				setSongFile(undefined);
				form.reset();
			} catch (e) {
				setGeneralError(true);
			}
		} else if (lyricsFile === undefined) {
			setNoLyrics(true);
		} else if (songFile === undefined) {
			setNoSong(true);
		} else {
			setInvalidMail(true);
		}

		setIsLoading(false);
	};

	return (
		<div className={theme.mainContainer} id='uploadSongForm'>
			<Logo size={250} classChildName={theme.giganticLogo1} white />
			<Logo size={200} classChildName={theme.giganticLogo2} white />
			<div className={'container ' + theme.layout}>
				<AnimationOnScroll
					animateOnce={true}
					animateIn='animate__slideInLeft'
					duration={1}
					delay={300}
				>
					<div>
						<h2>Subir&nbsp; composición&nbsp; musical</h2>
						<p>
              Te invitamos a realizar una composición sencilla, inspirada en la
              temática de esta temporada, sin producción, simplemente un
              instrumento y tu voz, grabarla con tu celular o una grabadora y
              subirla a esta plataforma.
						</p>
						<p>
              Desde <strong>Muy Humanos</strong> se irán seleccionando, se
              producirán en máxima calidad y se lanzarán para nutrir al mundo
              con música enriquecedora.
						</p>
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll
					animateOnce={true}
					animateIn='animate__slideInRight'
					duration={1}
					className={theme.formContainer}
				>
					<div className={theme.svgBackground}>
						<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
							<rect
								x={isOnMobile ? '5%' : '0'}
								y={isOnMobile ? '0' : '5%'}
								width={isOnMobile ? '90%' : '100%'}
								height={isOnMobile ? '100%' : '90%'}
								rx='30'
								ry='30'
								fill='#ebebeb'
								stroke='transparent'
								strokeWidth='5'
							/>
						</svg>
					</div>
					<div className={theme.textContent}>
						<strong>TÉRMINOS Y CONDICIONES:</strong>

						<ul>
							<li>
								{' '}
								<span>•</span> Los derechos de autor y los ingresos que se
                generen por dicho concepto, corresponden 100% al compositor/a de
                la obra.
							</li>
							<li>
								<span>•</span> Muy Humanos tiene autorización para producir
                fonogramas de las obras cargadas en esta plataforma.
							</li>
							<li>
								<span>•</span> La producción de las canciones, su distribución y
                la contratación de intérpretes, es responsabilidad de Muy
                Humanos. Por lo tanto, el fonograma (no la obra) será propiedad
                de Muy Humanos.
							</li>
						</ul>
					</div>
					<div className={theme.divider}></div>
					<form className={theme.innerFormContainer} id='artist-submit-form'>
						<strong>¡Dejanos tu composición acá!</strong>
						<div>
							<label htmlFor='email'>MAIL DE CONTACTO:</label>
							<input
								id='artist-form-email'
								onChange={e => {
									setEmail(e.target.value);
								}}
								disabled={isLoading}
								required
								type='email'
								placeholder='ejemplo@gmail.com'
							/>
							{invalidMail && (
								<div className={theme.errorText}>
                  Por favor ingrese un mail valido
								</div>
							)}
						</div>
						<div>
							<label htmlFor='file-upload'>LETRA:</label>
							<div className={theme.customFileInput}>
								<div className={theme.customFileInputPlaceholder}>
									{lyricsFile === undefined
										? 'PDF, DOC y DOCX'
										: lyricsFile.name}
								</div>
								<input
									required
									type='file'
									id='artist-form-lyric'
									onChange={e => {
										const file = e.target.files?.[0];
										if (file) {
											setLyricsFile(file);
										}
									}}
									disabled={isLoading}
									accept='.pdf, , .doc, .docx'
									className={theme.fileInput}
								/>
								<img src={arrow} alt='flecha' className={theme.fileIcon} />
							</div>
							{noLyrics && (
								<div className={theme.errorText}>
                  Por favor ingrese una letra valida
								</div>
							)}
						</div>

						<div>
							<label htmlFor='file-upload'>AUDIO:</label>
							<div className={theme.customFileInput}>
								<div className={theme.customFileInputPlaceholder}>
									{songFile === undefined ? '.mp3, .wav, .wma' : songFile.name}
								</div>
								<img src={arrow} alt='flecha' className={theme.fileIcon} />
								<input
									id='artist-form-song'
									required
									type='file'
									accept='.mp3, .wav, .wma'
									onChange={e => {
										const file = e.target.files?.[0];
										if (file) {
											setSongFile(file);
										}
									}}
									disabled={isLoading}
									className={theme.fileInput}
								/>
							</div>
							{noSong && (
								<div className={theme.errorText}>
                  Por favor ingrese un audio valido
								</div>
							)}
						</div>
						{isLoading ? (
							<div className={theme.ldsDualRing}></div>
						) : (
							<a
								onClick={isLoading ? undefined : handleSubmit}
								className='btn btn-custom btn-lg page-scroll'
							>
                Subir
							</a>
						)}
						{generalError && (
							<div className={theme.errorText} style={{padding: '0px 20px'}}>
                Error al subir el formulario, inténtelo de nuevo mas tarde.
							</div>
						)}
						{isSuccess && (
							<div className={theme.successText}>
                Formulario subido con éxito{' '}
							</div>
						)}
					</form>
				</AnimationOnScroll>
			</div>
		</div>
	);
}

export default UploadSongForm;
