import React from 'react';
import theme from './about.theme.module.scss';
import Logo from '../logo/logo';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {processText, replaceSpaces} from '../../consts';

type AboutProps = {
	title?: string;
	text?: string;
	videoUrl?: string;
	videoPosterUrl?: string;
};

function About({title, text, videoUrl, videoPosterUrl}: AboutProps) {
	return (
		<div id='about' className={theme.mainContainer}>
			<Logo size={400} classChildName={theme.giganticLogo1} white />
			<Logo size={280} classChildName={theme.giganticLogo2} white />
			<Logo size={180} classChildName={theme.giganticLogoMobile} white />
			<div className='container'>
				<div className='row'>
					<AnimationOnScroll
						animateOnce={true}
						animateIn='animate__slideInLeft'
						duration={1}
						delay={300}
						className='col-xs-12 col-md-6'
					>
						<div className={theme.aboutText}>
							<h2>{replaceSpaces(title)} </h2>
							<p>{processText(text)}</p>
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll
						animateOnce={true}
						animateIn='animate__slideInRight'
						delay={300}
						duration={1}
						className='col-xs-12 col-md-6'
					>
						{' '}
						<video
							controls
							className={theme.video}
							key={videoUrl}
							poster={videoPosterUrl}
						>
							<source src={videoUrl} type='video/mp4' />
              Your browser does not support HTML video.
						</video>{' '}
					</AnimationOnScroll>
				</div>
			</div>
		</div>
	);
}

export default About;
