import React, {useState, useEffect} from 'react';
import theme from './textContent.theme.module.scss';
import type ContactInfo from '../../models/contactInfo';
import Loader from '../loader/loader';
import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import MuyHumanosFooter from '../footer/footer';
import {useLocation} from 'react-router-dom';
import type DisparadorTextContent from '../../models/disparadorTextContent';
import {processText, replaceSpaces} from '../../consts';
import {getDisparadorTextContentById} from '../../repositories/landingRepository';
import NotFoundPage from '../notFoundPage/notFoundPage';
import {AnimationOnScroll} from 'react-animation-on-scroll';

type TextContentProps = {
	isAppLoading: boolean;
	contactInfo: ContactInfo | undefined;
};

function TextContent({isAppLoading, contactInfo}: TextContentProps) {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const contentId = query.get('id');
	const stateTextContent: DisparadorTextContent | undefined = location.state as
    | DisparadorTextContent
    | undefined;
	const [isLoading, setIsLoading] = useState(true);
	const [textContent, setTextContent] = useState<
	DisparadorTextContent | undefined
	>(undefined);
	const handleGetContent = async () => {
		if (contentId === null || contentId === undefined) {
			setTextContent(undefined);
			setIsLoading(false);
			return;
		}

		const dbTextContent = await getDisparadorTextContentById(contentId);
		setTextContent(dbTextContent);
		setIsLoading(false);
	};

	useEffect(() => {
		if (stateTextContent === null) {
			void handleGetContent();
		} else {
			setIsLoading(false);
			setTextContent(stateTextContent);
		}
	}, []);
	if (!(isLoading || isAppLoading) && textContent === undefined) {
		return (
			<NotFoundPage isAppLoading={isAppLoading} contactInfo={contactInfo} />
		);
	}

	return (
		<>
			{(isLoading || isAppLoading) && <Loader />}
			<div className={theme.mainContainer}>
				<Logo size={300} classChildName={theme.giganticLogo} />
				<Logo size={250} classChildName={theme.giganticLogo2} />
				<Navigation isForPage={true} />
				<div
					className={theme.banner}
					style={{backgroundImage: `url(${textContent?.coverImageUrl})`}}
				>
					<div className={theme.overlay}></div>
					<AnimationOnScroll
						animateOnce={true}
						animateIn='animate__slideInLeft'
						duration={1}
						className={'container ' + theme.hashtag}
					>
            #<span>{replaceSpaces(textContent?.hashtag)}</span>
					</AnimationOnScroll>
				</div>
				<div className='container'>
					<AnimationOnScroll
						animateOnce={true}
						animateIn='animate__slideInLeft'
						duration={1}
						className={theme.titleRow}
					>
						<h2>{textContent?.title ?? 'Loading'}</h2>
					</AnimationOnScroll>
					<div className={theme.normalText}>
						{processText(textContent?.bodyText)}
					</div>
				</div>
			</div>
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true} />
		</>
	);
}

export default TextContent;
