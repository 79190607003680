import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
export default class ContactInfo {
	public static converter: FirestoreDataConverter<ContactInfo> = {
		toFirestore(contactInfo: ContactInfo): DocumentData {
			return {...contactInfo};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): ContactInfo {
			const data = snapshot.data() as ContactInfo;
			return new ContactInfo(data.address, data.instagram, data.mail);
		},
	};

	constructor(public address: string, public instagram: string, public mail: string) {
	}
}
