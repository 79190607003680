import React from 'react';
import theme from './disparadorSingleContentElement.theme.module.scss';
import {mobileBreakpoint, processText, replaceSpaces} from '../../../consts';
import {type DisparadorContentInterface} from '../../../models/disparadorContentInterface';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {Link} from 'react-router-dom';

type DisparadorSingleContentElementProps = {
	content: DisparadorContentInterface;
};

function DisparadorSingleContentElement({
	content,
}: DisparadorSingleContentElementProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	const isTextContent = content.videos === undefined;
	return (
		<AnimationOnScroll
			animateOnce={true}
			animateIn='animate__slideInUp'
			duration={1}
			className={theme.mainContainer}
		>
			<div className={theme.svgBackground}>
				<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
					<rect
						x={isOnMobile ? '5%' : '0'}
						y={isOnMobile ? '0' : '5%'}
						width={isOnMobile ? '90%' : '100%'}
						height={isOnMobile ? '100%' : '90%'}
						rx='30'
						ry='30'
						fill='#262a29'
						stroke='transparent'
						strokeWidth='5'
					/>
				</svg>
			</div>
			<div className={theme.imageContainer}>
				<img
					src={content.mainImageUrl}
					className='loadingImage'
					alt='Disparador main image'
				/>
			</div>
			<div className={theme.textContent}>
				<div className={theme.columnStart}>
					<div className={theme.hashtag}>
            #<span>{content.hashtag}</span>
					</div>

					<h2>{replaceSpaces(content.title)}</h2>
					<div className={theme.author}>{content.author ?? ''}</div>

					<div className={theme.summary}>{processText(content.summary)}</div>
					<Link
						to={{
							pathname: isTextContent ? '/contenido-texto' : '/contenido-video',
						}}
						state={content}
						className='btn btn-custom btn-lg page-scroll'
					>
            Ver contenido
					</Link>
				</div>
			</div>
		</AnimationOnScroll>
	);
}

export default DisparadorSingleContentElement;
