import React from 'react';
import Logo from '../logo/logo';
import theme from './footer.theme.module.scss';
import type ContactInfo from '../../models/contactInfo';
import {mobileBreakpoint} from '../../consts';
type FooterProps = {
	contactInfo?: ContactInfo;
	secondaryColor?: boolean;
};

function MuyHumanosFooter({contactInfo, secondaryColor}: FooterProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	return (
		<div className={secondaryColor ? (theme.mainContainer + ' ' + theme.alternativeColor) : theme.mainContainer}>
			<div className='container'>

				<div className={theme.innerRow}>
					<div className={theme.MhLogo}><Logo size={isOnMobile ? 70 : 90} white={!secondaryColor} /></div>
					<div className={theme.miniColumn}>
						<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

							<svg className={theme.logo} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30px' height='30px'>
								<path fill={secondaryColor ? '#fe097b' : 'white'}
									d='M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z' />
							</svg>

							{contactInfo?.instagram ?? 'loading'}
						</div>
						<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
							<svg className={theme.logo} xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' viewBox='0 -960 960 960'
								fill={secondaryColor ? '#fe097b' : 'white'}>
								<path
									d='M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z' />
							</svg>
							{contactInfo?.mail ?? 'loading'}
						</div>
						<div className={theme.direction}>{contactInfo?.address ?? 'loading'}

						</div>
					</div>
				</div>

				<div className={theme.secondRow} >
					<p style={{textAlign: 'center'}}>
          © Copyright 2024 Muy Humanos. All rights reserved. Made By
						{' '}
						<a href='https://www.linkedin.com/in/manuel-barrabino-8234461b7/'>Manuel Barrabino</a>
					</p>
				</div>
			</div>

		</div>
	);
}

export default MuyHumanosFooter;
