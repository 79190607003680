import {
	type DocumentData, type FirestoreDataConverter, type QueryDocumentSnapshot,
} from 'firebase/firestore';

export default class Disparador {
	static localStorageDataKey = 'seasonsData';
	public static converter: FirestoreDataConverter<Disparador> = {
		toFirestore(season: Disparador): DocumentData {
			const filteredData = Object.fromEntries(Object.entries(season).map(([propertyName, value]) => {
				if (value !== undefined) {
					return [propertyName, value];
				}

				return [propertyName, null];
			}));
			return {...filteredData};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): Disparador {
			const withUndefined = Object.fromEntries(Object.entries(snapshot.data()).map(([propertyName, value]) => {
				if (value === null) {
					return [propertyName, undefined];
				}

				return [propertyName, value];
			}));
			const data = withUndefined as Disparador;
			return new Disparador(data.id, data.title, data.subTitle, data.coverImage, data.description, data.contentSummary);
		},
	};

	constructor(public id: string, public title: string, public subTitle: string, public coverImage?: string, public description?: string, public contentSummary?: string) {
	}
}
