import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
export default class Header {
	public static converter: FirestoreDataConverter<Header> = {
		toFirestore(header: Header): DocumentData {
			if (header.imageUrl === 'set-null') {
				return {
					title: header.title,
					paragraph: header.paragraph,
					subTitle: header.subTitle,
				};
			}

			return {...header};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): Header {
			const data = snapshot.data() as Header;
			return new Header(data.title, data.paragraph, data.subTitle, data.imageUrl);
		},
	};

	constructor(public title: string, public paragraph: string, public subTitle: string, public imageUrl: string) {
	}
}
