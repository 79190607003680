import React from 'react';
import theme from './image.theme.module.scss';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {processText, replaceSpaces} from '../../consts';
import {useNavigate} from 'react-router-dom';

type ImageProps = {
	title?: string;
	subTitle?: string;
	smallImage?: string;
	smallText?: boolean;
	childClass?: string;
	gotToDisparadores?: boolean;
};

function Image({
	title,
	subTitle,
	smallImage,
	smallText = false,
	childClass,
	gotToDisparadores = false,
}: ImageProps) {
	const goTo = useNavigate();
	return (
		<AnimationOnScroll
			animateOnce={true}
			animateIn='animate__slideInUp'
			duration={1}
			className={theme.imageContainer + ' ' + childClass}
		>
			<img
				src={smallImage}
				alt='Season Picture'
				className={theme.seasonImage + ' loadingImage'}
			></img>
			<div
				className={theme.overlay}
				onClick={
					gotToDisparadores
						? () => {
							goTo('/disparadores');
						}
						: () => null
				}
			></div>
			<div
				onClick={
					gotToDisparadores
						? () => {
							goTo('/disparadores');
						}
						: () => null
				}
				className={theme.textCenter}
			>
				<h2 style={{fontSize: smallText ? '40px' : ''}}>
					{replaceSpaces(title)}
				</h2>
				<p className={smallText ? theme.smallP : ''}>{processText(subTitle)}</p>
			</div>
		</AnimationOnScroll>
	);
}

export default Image;
