import React from 'react';
import theme from './navigation.theme.module.scss';
import Logo from '../logo/logo';
import {mobileBreakpoint} from '../../consts';

type NavigationProps = {
	isForPage?: boolean;

};

function Navigation({isForPage = false}: NavigationProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	return (
		<nav id='menu' className={`navbar navbar-default navbar-fixed-top ${theme.mainNavContainer}`}>
			<div className={'container ' + theme.overlay}>
				<div className={'navbar-header ' + theme.myNavHeader }>
					<button
						type='button'
						className={`navbar-toggle collapsed ${theme.navbarToggle}`}
						data-toggle='collapse'
						data-target='#bs-example-navbar-collapse-1'
					>
						{' '}
						<span className='sr-only'>Toggle navigation</span>
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
					</button>
					<a className={`navbar-brand page-scroll ${theme.mainHeaderTitle}`} href={isForPage ? '/#page-top' : '#page-top'}>
						<Logo />
						<div className={theme.logoText}>MUY&nbsp; HUMANOS</div>

					</a>
					{' '}
				</div>

				<div
					className='collapse navbar-collapse'
					id='bs-example-navbar-collapse-1'
				>
					<ul className={`nav navbar-nav navbar-right ${theme.navRow}`}>
						<li>
							<a
								href={isForPage ? '/#about' : '#about'}
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                ¿Quiénes Somos?
							</a>
						</li>
						<li>
							<a
								href='/disparadores'
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                Disparadores
							</a>
						</li>
						<li>
							<a
								href={isForPage ? '/#temporada' : '#temporada'}
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                Último disparador
							</a>
						</li>
						<li>
							<a
								href={isForPage ? '/#sponsors' : '#sponsors'}
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                Sponsors
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default Navigation;
