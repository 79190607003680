import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
export default class AboutUs {
	public static converter: FirestoreDataConverter<AboutUs> = {
		toFirestore(header: AboutUs): DocumentData {
			return {...header};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): AboutUs {
			const data = snapshot.data() as AboutUs;
			return new AboutUs(data.title, data.text, data.videoUrl, data.videoPosterUrl);
		},
	};

	constructor(public title: string, public text: string, public videoUrl: string, public videoPosterUrl: string) {
	}
}
