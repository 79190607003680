import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
export default class ArtistSong {
	public static converter: FirestoreDataConverter<ArtistSong> = {
		toFirestore(artistSong: ArtistSong): DocumentData {
			return {...artistSong};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): ArtistSong {
			const data = snapshot.data() as ArtistSong;
			return new ArtistSong(data.id, data.mail, data.lyricsUrl, data.songUrl, new Date(data.uploadedDate), data.disparadorName);
		},
	};

	constructor(public id: string, public mail: string, public lyricsUrl: string, public songUrl: string, public uploadedDate: Date, public disparadorName: string) {
	}
}
