/* eslint-disable unused-imports/no-unused-vars */
import {
	collection, getDocs, doc, type Query, type DocumentData, type QueryDocumentSnapshot, query, where,
	type DocumentReference,
	type FieldPath,
	type SnapshotOptions,
	type SnapshotMetadata,
	setDoc,
} from 'firebase/firestore';
import 'firebase/firestore';
import {firestore} from '../firebase';
import Disparador from '../models/season';
import Header from '../models/header';
import AboutUs from '../models/aboutUs';
import Sponsor from '../models/sponsor';
import ContactInfo from '../models/contactInfo';
import {LandingData} from '../models/landingData';
import DisparadorTextContent from '../models/disparadorTextContent';
import DisparadorVideoContent from '../models/disparadorVideoContent';
import ArtistSong from '../models/artistSong';
import {Routes, uploadFile} from './storageClient';

const seasonsCollectionKey = 'seasons';
const landingCollectionKey = 'landing';
const sponsorsDocumentKey = 'sponsors';
const aboutUsDocumentKey = 'aboutUs';
const headerDocumentKey = 'header';
const contactInfoDocumentKey = 'contactInfo';
const disparadorTextContentCollectionKey = 'disparadorTextContents';
const disparadorVideoContentCollectionKey = 'disparadorVideoContents';
const artistSongCollectionKey = 'artistSongs';
const timeToLeave = 1000 * 60 * 15; // 15 minutes in milliseconds
const collectionReference = collection(firestore, landingCollectionKey);

enum DataType {
	seasons = 'seasons',
	landingData = 'landingData',
}

export async function getSeasons(): Promise<Disparador[]> {
	const reference = collection(firestore, seasonsCollectionKey).withConverter(Disparador.converter);
	const shouldGetDataFromCache = shoudGetDataFromCache(DataType.seasons);
	let querySnapshot;
	if (shouldGetDataFromCache) {
		querySnapshot = getDocsFromCache(reference);
	} else {
		querySnapshot = (await getDocs(reference)).docs;
		saveDocsToCache(reference, querySnapshot);
	}

	const seasons: Disparador[] = [];
	querySnapshot.forEach(doc => {
		seasons.push(doc.data());
	});
	return seasons;
}

export async function getLandingData(): Promise<LandingData> {
	let querySnapshotDocs;
	const shouldGetDataFromCache = shoudGetDataFromCache(DataType.landingData);
	if (shouldGetDataFromCache) {
		querySnapshotDocs = getDocsFromCache(collectionReference);
	} else {
		querySnapshotDocs = (await getDocs(collectionReference)).docs;
		saveDocsToCache(collectionReference, querySnapshotDocs);
	}

	const aboutUsSnapshot = querySnapshotDocs.find(doc => doc.id === aboutUsDocumentKey);
	const aboutUsData = aboutUsSnapshot!.data() as AboutUs;
	const aboutUsObject = new AboutUs(aboutUsData.title, aboutUsData.text, aboutUsData.videoUrl, aboutUsData.videoPosterUrl);

	const headerSnapshot = querySnapshotDocs.find(doc => doc.id === headerDocumentKey);
	const headerData = headerSnapshot!.data() as Header;
	const headerObject = new Header(headerData.title, headerData.paragraph, headerData.subTitle, headerData.imageUrl);

	const contactSnapshot = querySnapshotDocs.find(doc => doc.id === contactInfoDocumentKey);
	const contactData = contactSnapshot!.data() as ContactInfo;
	const contactObject = new ContactInfo(contactData.address, contactData.instagram, contactData.mail);

	const sponsorsSnapshot = querySnapshotDocs.find(doc => doc.id === sponsorsDocumentKey);
	const sponsorsData = (sponsorsSnapshot!.data().list as Sponsor[]);
	const sponsorsObject = sponsorsData.map(sponsor => new Sponsor(sponsorsSnapshot!.id, sponsor.name, sponsor.url, sponsor.isMain));

	const objectToReturn = new LandingData(headerObject, aboutUsObject, contactObject, sponsorsObject);
	return objectToReturn;
}

function shoudGetDataFromCache(dataType: DataType): boolean {
	const dataKey = 'data-time-stamp-' + dataType.toString();
	const dataTimeStamp = localStorage.getItem(dataKey);
	const currentTimeStamp = new Date().getTime();
	if (!dataTimeStamp) {
		localStorage.setItem(dataKey, currentTimeStamp.toString());
		return false;
	}

	const timeDifference = currentTimeStamp - Number(dataTimeStamp);
	const isDataValid = timeDifference < timeToLeave;
	if (!isDataValid) {
		localStorage.setItem(dataKey, currentTimeStamp.toString());
	}

	console.log('shoudGetDataFromCache: ' + dataType + ' ' + isDataValid);

	return isDataValid;
}

function saveDocsToCache<AppModelType, DbModelType extends DocumentData>(query: Query<AppModelType, DbModelType>, querySnapshotDocs: Array<QueryDocumentSnapshot<AppModelType, DbModelType>>): void {
	const isSeasonQuery = query.converter === Disparador.converter;
	const dataToSave = JSON.stringify(querySnapshotDocs.map(doc => ({data: doc.data(), id: doc.id})));
	localStorage.setItem(isSeasonQuery ? Disparador.localStorageDataKey : LandingData.localStorageDataKey, dataToSave);
}

type SavedData<T> = {
	data: T;
	id: string;
};

function getDocsFromCache<AppModelType, DbModelType extends DocumentData>(query: Query<AppModelType, DbModelType>): Array<QueryDocumentSnapshot<AppModelType, DbModelType>> {
	const isSeasonQuery = query.converter === Disparador.converter;
	const rawData = localStorage.getItem(isSeasonQuery ? Disparador.localStorageDataKey : LandingData.localStorageDataKey);
	const parsedData = JSON.parse(rawData!) as Array<SavedData<AppModelType>>;
	return parsedData.map(doc => new MockDocument<AppModelType, DbModelType>(doc.data, doc.id));
}

class MockDocument<AppModelType, DbModel extends DocumentData> implements QueryDocumentSnapshot<AppModelType, DbModel> {
	metadata: SnapshotMetadata;
	constructor(public myData: AppModelType, public myId: string) {
		this.metadata = {hasPendingWrites: false, fromCache: true, isEqual: () => false};
	}

	data(options?: SnapshotOptions | undefined): AppModelType {
		return this.myData;
	}

	exists(): this is QueryDocumentSnapshot<AppModelType, DbModel> {
		throw new Error('Method not implemented.');
	}

	get(fieldPath: string | FieldPath, options?: SnapshotOptions | undefined) {
		throw new Error('Method not implemented.');
	}

	get id(): string {
		return this.myId;
	}

	get ref(): DocumentReference<AppModelType, DbModel> {
		throw new Error('Method not implemented.');
	}
}

export async function getDisparadorTextContent(disparadorId: string): Promise<DisparadorTextContent[]> {
	const reference = collection(firestore, disparadorTextContentCollectionKey).withConverter(DisparadorTextContent.converter);
	const myQuery = query(reference, where('disparadorId', '==', disparadorId));
	const querySnapshot = await getDocs(myQuery);
	const disparadorTextContents = querySnapshot.docs.map(doc => doc.data());
	return disparadorTextContents;
}

export async function getDisparadorVideoContent(disparadorId: string): Promise<DisparadorVideoContent[]> {
	const reference = collection(firestore, disparadorVideoContentCollectionKey).withConverter(DisparadorVideoContent.converter);
	const myQuery = query(reference, where('disparadorId', '==', disparadorId));
	const querySnapshot = await getDocs(myQuery);
	const disparadorTextContents = querySnapshot.docs.map(doc => doc.data());
	return disparadorTextContents;
}

export async function getDisparadorTextContentById(disparadorTextContentId: string): Promise<DisparadorTextContent | undefined> {
	const reference = collection(firestore, disparadorTextContentCollectionKey).withConverter(DisparadorTextContent.converter);
	const myQuery = query(reference, where('id', '==', disparadorTextContentId));
	const querySnapshot = await getDocs(myQuery);
	if (querySnapshot.docs.length === 0) {
		return undefined;
	}

	const disparadorTextContent = querySnapshot.docs[0];
	return disparadorTextContent?.data();
}

export async function getDisparadorVideoContentById(disparadorVideoContentId: string): Promise<DisparadorVideoContent | undefined> {
	const reference = collection(firestore, disparadorVideoContentCollectionKey).withConverter(DisparadorVideoContent.converter);
	const myQuery = query(reference, where('id', '==', disparadorVideoContentId));
	const querySnapshot = await getDocs(myQuery);
	if (querySnapshot.docs.length === 0) {
		return undefined;
	}

	const disparadorVideoContent = querySnapshot.docs[0];
	return disparadorVideoContent?.data();
}

export async function uploadArtistSong(email: string, lyricsFile: File, songFile: File, disparadorName: string): Promise<void> {
	const docReference = doc(collection(firestore, artistSongCollectionKey).withConverter(ArtistSong.converter));
	const lyricsUrl = await uploadFile(lyricsFile, Routes.LyricsFilePath, docReference.id);
	const songUrl = await uploadFile(songFile, Routes.SongFilePath, docReference.id);
	const today = new Date();
	const artistSong = new ArtistSong(docReference.id, email, lyricsUrl, songUrl, today, disparadorName);
	await setDoc(docReference, artistSong);
}
