
import React, {useState, useEffect} from 'react';
import theme from './videoContent.theme.module.scss';
import type ContactInfo from '../../models/contactInfo';
import Loader from '../loader/loader';
import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import MuyHumanosFooter from '../footer/footer';
import {useLocation} from 'react-router-dom';
import type DisparadorVideoContent from '../../models/disparadorVideoContent';
import {mobileBreakpoint, processText, replaceSpaces} from '../../consts';
import NotFoundPage from '../notFoundPage/notFoundPage';
import {getDisparadorVideoContentById} from '../../repositories/landingRepository';
import tick from './tick.svg';
import {LazyLoadImage} from 'react-lazy-load-image-component';
type VideoContentProps = {
	isAppLoading: boolean;
	contactInfo: ContactInfo | undefined;
};

function VideoContent({isAppLoading, contactInfo}: VideoContentProps) {
	const location = useLocation();
	const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
	const [showOverlay, setShowOverlay] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [lastTimeOut, setLastTimeOut] = useState<NodeJS.Timeout | undefined>(undefined);
	const [secondsTillNextVideo, setSecondsTillNextVideo] = useState(15);
	const query = new URLSearchParams(location.search);
	const contentId = (query.get('id'));
	const stateVideoContent: DisparadorVideoContent | undefined = location.state as DisparadorVideoContent | undefined;
	const [isLoading, setIsLoading] = useState(true);
	const [videoContent, setVideoContent] = useState<DisparadorVideoContent | undefined>(undefined);
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	const handleGetContent = async () => {
		if (contentId === null || contentId === undefined) {
			setVideoContent(undefined);
			setIsLoading(false);
			return;
		}

		const dbVideoContent = await getDisparadorVideoContentById(contentId);
		setVideoContent(dbVideoContent);
		setIsLoading(false);
	};

	useEffect(() => {
		if (stateVideoContent === null) {
			void handleGetContent();
		} else {
			setIsLoading(false);
			setVideoContent(stateVideoContent);
		}
	}, []);
	if (!(isLoading || isAppLoading) && videoContent === undefined) {
		return <NotFoundPage isAppLoading={isAppLoading} contactInfo={contactInfo} />;
	}

	const recursiveCounter = (counter: number) => {
		if (counter === 0) {
			setShowOverlay(false);
			setSelectedVideoIndex(selectedVideoIndex + 1);
			setSecondsTillNextVideo(15);
			return;
		}

		setLastTimeOut(setTimeout(() => {
			setSecondsTillNextVideo(counter - 1);
			recursiveCounter(counter - 1);
		}, 1000));
	};

	return (
		<>
			{(isAppLoading) && <Loader />}
			<div className={theme.mainContainer } style={isPlaying ? {background: 'rgb(32, 32, 32)'} : {}}>
				<Logo size={300} classChildName={theme.giganticLogo} />
				<Logo size={250} classChildName={theme.giganticLogo2} />
				<Navigation isForPage={true}/>
				<div className='container'>

					<div className={theme.titleRow}>
						<div className={theme.hashtag}>#<span style={isPlaying ? {color: 'white'} : {}}>{replaceSpaces(videoContent?.hashtag)}</span></div>

					</div>
					<div className={theme.normalText} style={isPlaying ? {color: 'white'} : {}}>
						{processText(videoContent?.bodyText)}
					</div>
					<div className={theme.bodyRow}>
						<div className={theme.childVideo}>

							{showOverlay && <div className={theme.overlay}>
								<div className={theme.nextVideoTitle}>{videoContent?.videos[selectedVideoIndex + 1].title}</div>
								<div className={theme.startCounterText}>Va a Comenzar en <span>{secondsTillNextVideo} segundos</span> </div>
								<LazyLoadImage src={videoContent?.videos[selectedVideoIndex + 1].imageUrl} alt='Next Video Poster' className={theme.nextVideoPoster} />
								<a
									onClick={
										() => {
											setShowOverlay(false);
											setSelectedVideoIndex(selectedVideoIndex + 1);
										}
									}
									className='btn btn-custom btn-lg page-scroll'
								>
              Ver Siguiente
								</a>
								<a
									onClick={
										() => {
											setShowOverlay(false);
											if (lastTimeOut !== undefined) {
												clearTimeout(lastTimeOut);
											}
										}
									}
									className={theme.cancelButton + ' btn btn-lg page-scroll'}
								>
              Cancelar
								</a>
							</div>}
							<video controls className={theme.videoContainer} key={videoContent?.videos[selectedVideoIndex].url} poster={videoContent?.videos[selectedVideoIndex].imageUrl}
								onEnded={
									() => {
										const isLastVideo = selectedVideoIndex === (videoContent?.videos.length ?? 1) - 1;
										if (!isLastVideo) {
											setShowOverlay(true);
											recursiveCounter(15);
										}
									}

								}
								onPlay={
									() => {
										setIsPlaying(true);
									}
								}
								onPause={
									() => {
										setIsPlaying(false);
									}
								}

							>
								<source src={videoContent?.videos[selectedVideoIndex].url} type='video/mp4' />

  Your browser does not support HTML video.
							</video>
						</div>
						<div className={theme.separator}></div>
						<div className={theme.childVideoList} style={isPlaying && !isOnMobile ? {flex: 0.4, height: '540px'} : {}}>
							{videoContent?.videos.map((video, index) => (
								<div key={video.url} className={index === selectedVideoIndex ? theme.videoItemContainerSelected : theme.videoItemContainer} style={isPlaying && !isOnMobile ? {padding: '5px 0px', paddingRight: '7px'} : {}} onClick={

									() => {
										setSelectedVideoIndex(index);
									}
								}
								>
									<LazyLoadImage className={theme.videoImage} style={isPlaying && !isOnMobile ? {display: 'none'} : {}} key={video.url} src={video.imageUrl}/>
									<div className={theme.videoItemColumn}>
										<div className={theme.videoTitle}>{video.title}.</div>
										<div className={theme.videoSubTitle}> {video.duration ?? '1:50min'} </div>
									</div>
									<img src={tick} alt='white tick' height={35} width={35} />

								</div>
							))}
						</div>
					</div>

				</div>

			</div>
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true}/>
		</>
	);
}

export default VideoContent;

