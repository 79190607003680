import React from 'react';
import type Disparador from './models/season';

export const mobileBreakpoint = 768;
export const tabletBreakpoint = 1160;

export const thematicTriggerIsBefore = (aTrigger: Disparador, bTrigger: Disparador) => {
	try {
		const firstNumber = parseInt(aTrigger.title.split(' ')[1], 10);
		const secondNumber = parseInt(bTrigger.title.split(' ')[1], 10);
		return firstNumber - secondNumber;
	} catch (e) {
		return 0;
	}
};

export const processText = (text?: string) => {
	if (text === undefined) {
		return (<span>Loading...</span>);
	}

	const textChunks = text.split('\n');

	const dividedText = textChunks.map((chunk, index) => {
		// Replace *...* with <strong>...</strong>
		let strongReplaced = chunk.split('*').map((part, i) =>
			i % 2 === 1 ? <strong key={i}>{part}</strong> : part,
		);

		// Flatten the array before processing _..._ replacement
		strongReplaced = strongReplaced.flat();

		// Replace _..._ with <i>...</i>
		const finalChunks = strongReplaced.map((part, _) =>
			typeof part === 'string'
				? part.split('_').map((subPart, j) =>
					j % 2 === 1 ? <i key={j + subPart}>{subPart}</i> : subPart,
				)
				: part,
		);

		return (
			<React.Fragment key={index}>
				{finalChunks.flat()}
				<br />
			</React.Fragment>
		);
	});

	return <>{dividedText}</>;
};

export const replaceSpaces = (text?: string) => {
	// Replaces spaces with &nbsp; symbol
	if (text === undefined) {
		return 'Loading...';
	}

	const textList = text.split('  ');
	const result = textList.reduce(
		(acc, curr) => (acc === '' ? curr : acc + '\u00A0 ' + curr),
		'',
	);

	return <>{result}</>;
};
