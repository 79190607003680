import React, {useEffect, useState} from 'react';
import theme from './disparadorContent.theme.module.scss';
import type Disparador from '../../models/season';
import Loader from '../loader/loader';
import Navigation from '../navigation/navigation';
import {getDisparadorTextContent, getDisparadorVideoContent} from '../../repositories/landingRepository';
import MuyHumanosFooter from '../footer/footer';
import type ContactInfo from '../../models/contactInfo';
import DisparadorSingleContentElement from './disparadorSingleContentElement/disparadorSingleContentElement';
import {useLocation} from 'react-router-dom';
import Logo from '../logo/logo';
import {type DisparadorContentInterface} from '../../models/disparadorContentInterface';
import UploadSongForm from '../uploadSongForm/uploadSongForm';
import {mobileBreakpoint, replaceSpaces} from '../../consts';

type DisparadorContentProps = {
	disparadores?: Disparador[];
	isAppLoading: boolean;
	contactInfo: ContactInfo | undefined;
};

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function DisparadorContent({disparadores, isAppLoading, contactInfo}: DisparadorContentProps) {
	const query = useQuery();
	const disparadorId = (query.get('id')!);

	const disparador = disparadores?.find(disparador => disparador.id === disparadorId);
	const [myLoading, setMyLoading] = useState(true);
	const [disparadorTextContent, setDisparadorTextContent] = useState<DisparadorContentInterface[] | undefined>(undefined);
	const compareContent = (a: DisparadorContentInterface, b: DisparadorContentInterface) => {
		if (a.order < b.order) {
			return -1;
		}

		if (a.order > b.order) {
			return 1;
		}

		return 0;
	};

	const handleGetContent = async () => {
		const textContentPromise = getDisparadorTextContent(disparadorId);
		const videoContentPromise = getDisparadorVideoContent(disparadorId);
		const [textContent, videoContent] = await Promise.all([textContentPromise, videoContentPromise]);
		setDisparadorTextContent([...textContent, ...videoContent].sort(compareContent));
		setMyLoading(false);
	};

	useEffect(() => {
		void handleGetContent();
	}, [disparador]);

	const isOnMobile = window.innerWidth < mobileBreakpoint;

	return (
		<>
			{(myLoading || isAppLoading) && <Loader />}
			<div id='disparadores' className={`text-center ${theme.mainContainer}`}>
				<Logo size={350} classChildName={theme.giganticLogo} />
				<Logo size={250} classChildName={theme.giganticLogo2} />
				<Navigation isForPage={true}/>
				<div className='container'>

					<div className={theme.titleRow}>
						<h2>{replaceSpaces(disparador?.title)}</h2>
						{!isOnMobile && <div>
							<a
								href='#uploadSongForm'
								className='btn btn-custom btn-lg page-scroll'
							>
              Subir composición musical
							</a>
						</div>}

					</div>
					<div className={theme.normalText}>
					Con el fin de hacer un bien a la sociedad a partir de tu talento componiendo música, te invitamos a nutrirte de los
siguientes mensajes, que haría tan bien que las personas conozcan; y ¡qué mejor manera que a través de la
música!
						<br/>
						<br/>
¡Inspirate y subí tu composición a <strong>Muy Humanos</strong>!
						<br/>
						<br/>
Temática de esta temporada: <strong>{disparador?.subTitle ?? 'Loading'}</strong>.
						<br/>
						<br/>
					</div>
					{isOnMobile && <div>
						<a
							href='#uploadSongForm'
							className='btn btn-custom btn-lg page-scroll'
						>
              Subir composición musical
						</a>
					</div>}

					<div className={theme.wrap}>
						{disparadorTextContent === undefined
							? 'loading'
							: disparadorTextContent.map((disparadoresElement, _) => (
								<DisparadorSingleContentElement
									content={disparadoresElement} key={disparadoresElement.title}
								/>
							))
						}
					</div>

				</div>

			</div>
			<UploadSongForm disparadorName={disparador?.title ?? 'Loading...'} />
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true}/>
		</>
	);
}

export default DisparadorContent;
