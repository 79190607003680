import React from 'react';
import theme from './logo.theme.module.scss';
import logoWhite from './svg/Iso-white.svg';
import logoYellow from './svg/Iso-yellow.svg';
import {mobileBreakpoint} from '../../consts';

type LogoProps = {
	white?: boolean;
	size?: number;
	marginRight?: number;
	classChildName?: string;
};

function Logo({white = false, size = 39, marginRight = 10, classChildName = ''}: LogoProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	const realSize = isOnMobile ? size * 0.85 : size;
	return (
		<img src={white ? logoWhite : logoYellow} style={{width: realSize, height: realSize, marginRight}} className={classChildName + ' ' + theme.logo} alt='logo' />
	);
}

export default Logo;
