import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
import {type DisparadorContentInterface} from './disparadorContentInterface';

export default class DisparadorTextContent implements DisparadorContentInterface {
	public static converter: FirestoreDataConverter<DisparadorTextContent> = {
		toFirestore(object: DisparadorTextContent): DocumentData {
			const filteredData = Object.fromEntries(Object.entries(object).map(([propertyName, value]) => {
				if (value !== undefined) {
					return [propertyName, value];
				}

				return [propertyName, null];
			}));

			return {...filteredData};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): DisparadorTextContent {
			const data = snapshot.data() as DisparadorTextContent;
			return new DisparadorTextContent(data.id, data.disparadorId, data.title, data.hashtag, data.summary, data.mainImageUrl, data.coverImageUrl, data.bodyText, data.order, data.author);
		},
	};

	constructor(public id: string, public disparadorId: string, public title: string, public hashtag: string, public summary: string, public mainImageUrl: string, public coverImageUrl: string, public bodyText: string, public order: number, public author?: string) {
	}
}
