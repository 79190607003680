import type Header from './header';
import type AboutUs from './aboutUs';
import type Sponsor from './sponsor';
import type ContactInfo from './contactInfo';

export class LandingData {
	static localStorageDataKey = 'landingData';

	constructor(public header: Header, public about: AboutUs, public contact: ContactInfo, public sponsors: Sponsor[]) {
	}
}
