import {
	type DocumentData, type QueryDocumentSnapshot, type FirestoreDataConverter,

} from 'firebase/firestore';
export default class Sponsor {
	public static converter: FirestoreDataConverter<Sponsor> = {
		toFirestore(header: Sponsor): DocumentData {
			return {...header};
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): Sponsor {
			const data = snapshot.data() as Sponsor;
			return new Sponsor(data.id, data.name, data.url, data.isMain);
		},
	};

	constructor(public id: string, public name: string, public url: string, public isMain: boolean) {
	}
}
