import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Header from '../header/header';
import About from '../about/about';
import Sponsors from '../sponsors/sponsors';
import type Disparador from '../../models/season';
import type HeaderObject from '../../models/header';
import type AboutUsObject from '../../models/aboutUs';
import SingleSeason from '../singleSeason/singleSeason';
import MuyHumanosFooter from '../footer/footer';
import Loader from '../loader/loader';
import type Sponsor from '../../models/sponsor';
import type ContactInfo from '../../models/contactInfo';
import Navigation from '../navigation/navigation';

type MainPageProps = {
	isLoading: boolean;
	seasons: Disparador[] | undefined;
	contactInfo: ContactInfo | undefined;
	sponsors: Sponsor[] | undefined;
	header: HeaderObject | undefined;
	aboutUs: AboutUsObject | undefined;

};

function MainPage({isLoading, seasons, contactInfo, sponsors, header, aboutUs}: MainPageProps) {
	const {hash, pathname} = useLocation();

	useEffect(() => {
		if (hash) {
			setTimeout(() => {
				const element = document.getElementById(hash.substring(1));
				if (element) {
					element.scrollIntoView();
				}
			}, 500);
		}
	}, [hash, pathname]);

	const getNumberFromSeasonName = (seasonName: string): number => {
		const seasonNumber = seasonName.split(' ')[1];
		try {
			return parseInt(seasonNumber, 10);
		} catch (error) {
			return 1000;
		}
	};

	const findLastSeason = (seasons: Disparador[] | undefined): Disparador | undefined => {
		if (seasons === undefined) {
			return undefined;
		}

		const lastSeason = seasons.reduce((prev, current) => (getNumberFromSeasonName(prev.title) > getNumberFromSeasonName(current.title) ? prev : current));
		return lastSeason;
	};

	return (
		<div>
			{isLoading && <Loader />}
			<Navigation />
			<Header title={header?.title} paragraph={header?.paragraph} subtitle={header?.subTitle} imageUrl={header?.imageUrl}/>
			<About
				title={aboutUs?.title}
				text={aboutUs?.text}
				videoUrl={aboutUs?.videoUrl}
				videoPosterUrl={aboutUs?.videoPosterUrl}
			/>
			<SingleSeason season={seasons === undefined ? undefined : findLastSeason(seasons)} />
			<Sponsors sponsors={sponsors} />
			<MuyHumanosFooter contactInfo={contactInfo}/>
		</div>
	);
}

export default MainPage;
