import React from 'react';
import theme from './notFoundPage.theme.module.scss';
import type ContactInfo from '../../models/contactInfo';
import Loader from '../loader/loader';
import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import MuyHumanosFooter from '../footer/footer';

type NotFoundPageProps = {
	isAppLoading: boolean;
	contactInfo: ContactInfo | undefined;
};

function NotFoundPage({isAppLoading, contactInfo}: NotFoundPageProps) {
	return (
		<>
			{(isAppLoading) && <Loader />}
			<div className={theme.mainContainer}>
				<Logo size={300} classChildName={theme.giganticLogo} />
				<Logo size={250} classChildName={theme.giganticLogo2} />
				<Navigation isForPage={true}/>
				<div className='container'>

					<div className={theme.titleRow}>
						<div className={'container ' + theme.hashtag}>#<span>404 &nbsp;NOT &nbsp;FOUND</span></div>

					</div>
					<div className={theme.normalText}>
						No se encontró la página que buscas
					</div>

				</div>

			</div>
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true}/>
		</>
	);
}

export default NotFoundPage;
