import React from 'react';
import theme from './header.theme.module.scss';
import Logo from '../logo/logo';
import {processText, replaceSpaces} from '../../consts';

type HeaderProps = {
	title?: string;
	paragraph?: string;
	subtitle?: string;
	imageUrl?: string;
};

export default function Header({title, paragraph, subtitle, imageUrl}: HeaderProps) {
	return (
		<header id='header' className={theme.mainContainer}>
			<div className={theme.intro} style={{backgroundImage: `url(${imageUrl})`}}>
				<div className={theme.overlay}>
					<div className={theme.introText}>
						<div className={theme.expandContainer}>
							<div className={theme.transparentContainer}>
								<div className={theme.titleRow}>
									<Logo size={70}/>
									<h1 className={theme.megaText}>
										{ replaceSpaces(title) }

									</h1></div>
								<p>{processText(paragraph) }</p>
								<p className={theme.subtitle}>{subtitle ?? 'Loading'}</p>
							</div>
						</div>
						<a
							href='#about'
							className= {`btn btn-custom btn-lg page-scroll ${theme.btn}`}
						>
              QUIERO SABER MÁS
						</a>
						{' '}
					</div>
				</div>
			</div>
		</header>
	);
}

