import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDNjXAf6mMwrl7nOTF5BMwQKg7kgFGzn_A',
	authDomain: 'muyhumano.firebaseapp.com',
	projectId: 'muyhumano',
	storageBucket: 'muyhumano.appspot.com',
	messagingSenderId: '938248613133',
	appId: '1:938248613133:web:3ae47f5f3305f093081444',
};

const app = initializeApp(firebaseConfig);
export default app;

export const firestore = getFirestore(app);

export const storage = getStorage(app);

