import {
	ref, uploadBytes, getDownloadURL, deleteObject,
} from 'firebase/storage';
import {storage} from '../firebase';

export enum Routes {
	SongFilePath = 'artists-songs',
	LyricsFilePath = 'artists-lyrics',
}

export async function uploadFile(file: File, path: Routes, fileInStorageName?: string): Promise<string> {
	const storageRef = ref(storage, `${path.toString()}/${fileInStorageName ?? file.name}`);
	await uploadBytes(storageRef, file);
	const url = await getDownloadURL(storageRef);
	return url;
}

export async function deleteFile(path: Routes, fileName: string): Promise<void> {
	const storageRef = ref(storage, `${path.toString()}/${fileName}`);
	await deleteObject(storageRef);
}

