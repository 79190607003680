import React from 'react';
import theme from './loader.theme.module.scss';
import Logo from '../logo/logo';

function Loader() {
	return (
		<div className={theme.mainContainer}>

			<Logo size={70} classChildName={theme.loader}/>

		</div>
	);
}

export default Loader;
